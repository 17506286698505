<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-md-3">
              Search
            </div>
            <div class="col-sm-5">
              <input
                type="text"
                v-model="searchForm"
                class="form-control"
                placeholder="type item name..."
                @input="debounceSearch"
              />
              <span v-if="typing"> <em> Mencari .. </em> </span>
            </div>
            <div class="col-sm-1">
              <router-link
                class="btn btn-block btn-primary"
                :to="{ path: 'items/create' }"
              >
                <i class="fa fa-plus"></i>
              </router-link>
            </div>
            <div class="col-sm-1">
              <router-link
                class="btn btn-block btn-primary"
                :to="{ path: 'items/bulk-upload-single' }"
              >
                <i class="fa fa-upload"></i>
              </router-link>
            </div>
          </div>
          <div class="row justify-content-end">
            <label for="" class="col-sm-auto">Count </label>
            <strong class="col-sm-auto">{{ formatNumber(totalRows) }}</strong>
          </div>
          <b-table
            sticky-header="500px"
            :isLoading="isLoading"
            responsive="xl"
            show-empty
            striped
            :busy="isLoading"
            :fields="fields"
            hover
            :items="items"
          >
            <template #cell(No)="row">
              {{ (currentPage - 1) * perPage + (row.index + 1) }}
            </template>
            <template #cell(name)="row">
              <router-link
                :to="{ path: '/items/detail/' + row.item.item_id }"
                class="link"
              >
                {{ row.item.name }}
              </router-link>
            </template>
            <template #cell(vendor_id_ax)="row">
              <div style="width: 150px">
                {{ row.item.vendor_id_ax ? row.item.vendor_id_ax : '-' }}
              </div>
            </template>
            <template #cell(created)="row">
              <div style="width: 250px">
                {{ row.item.created ? formatDate(row.item.created) : '-' }}
              </div>
            </template>
            <template #cell(modified)="row">
              <div style="width: 250px">
                {{ row.item.modified ? formatDate(row.item.modified) : '-' }}
              </div>
            </template>
            <template v-slot:cell(action)="row">
              <div class="btn-group" role="group" aria-label="Basic example">
                <router-link
                  :to="{ path: '/ax/item/' + row.item.id }"
                  class="link btn btn-primary"
                >
                  <i class="fa fa-pencil"></i>
                </router-link>
              </div>
            </template>
            <template v-slot:table-busy>
              <div class="text-center text-dark my-2">
                <b-spinner
                  class="align-middle"
                  variant="dark"
                  type="grow"
                  label="Loading .."
                >
                </b-spinner>
                Loading ..
              </div>
            </template>
          </b-table>
        </div>
        <b-row class="justify-content-start ml-1">
          <b-col lg="3">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              aria-controls="my-table"
            />
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapState, mapActions } from 'vuex';
export default {
  name: 'RegisterItem',
  data() {
    return {
      filter: {},
      items_search: [],
      currentPage: 1,
      perPage: 100,
      fields: [
        { key: 'No', label: 'No' },
        { key: 'name', label: 'Name' },
        { key: 'vendor', label: 'Vendor' },
        { key: 'vendor_id_ax', label: 'Vendor AX' },
        { key: 'single_idax', label: 'Single AX' },
        { key: 'created', label: 'created' },
        { key: 'modified', label: 'modified' },
        { key: 'action', label: '' },
      ],
      typing: '',
      searchForm: null,
    };
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.ItemSoax.isLoading,
      isError: (state) => state.ItemSoax.isError,
      totalRows: (state) => state.ItemSoax.totalRows,
      items: (state) => state.ItemSoax.items,
    }),
  },
  watch: {
    currentPage() {
      this.actionFetch();
    },
    $route: {
      immediate: true,
      handler(to) {
        document.title =
          to.meta.title ||
          'Item Approved AX | Content Management System Ebooks Gramedia.com';
      },
    },
  },
  mounted() {
    this.actionFetch();
  },
  methods: {
    ...mapActions('ItemSoax', ['fetch', 'search']),
    actionFetch() {
      let payload = {
        page: this.currentPage,
        limit: this.perPage,
      };
      if (this.searchForm) payload.q = this.searchForm;
      this.fetch(payload);
    },
    formatDate(date) {
      return new Date(date).toString();
    },
    formatTimeinfo(date) {
      return moment(date).fromNow();
    },
    formatNumber(value) {
      if (value) {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
      }
      return '0';
    },
    debounceSearch(event) {
      this.typing = 'You are typing';
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.typing = null;
        this.message = event.target.value;
        if (this.message.length > 0) {
          this.fetch({
            q: event.target.value,
            page: 1,
            limit: this.perPage,
          });
        } else {
          this.actionFetch();
        }
      }, 600);
    },
  },
};
</script>

<style lang="scss" scoped></style>
